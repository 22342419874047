<template>
	<div class="content-wrapper talent-analysis-page">
		<h1 class="mb-5">Talent Analysis</h1>
		<b-card class="mb-4">
			<b-row class="my-1 align-items-end">
				<b-col lg="5">
					<label>Select a file to upload (.xlsx)</label>
					<b-form-file
						v-model="file"
						placeholder="Choose a file or drop it here..."
						drop-placeholder="Drop file here..."
						accept=".xlsx, .XLSX"
					></b-form-file>
					<b-icon
						v-if="loadingFile"
						class="loading-file"
						icon="arrow-clockwise"
						animation="spin"
						font-scale="2"
					></b-icon>
				</b-col>
				<b-col lg="5">
					<b-button v-if="isLoading" variant="primary" disabled>
						<b-spinner small type="grow"></b-spinner>
						Loading...
					</b-button>
					<b-button
						v-else
						variant="primary"
						:disabled="file == null"
						@click="uploadItem"
						>Upload</b-button
					>
				</b-col>
			</b-row>
		</b-card>
		<b-card class="mb-4">
			<b-row class="my-1">
				<b-col lg="5">
					<b-input-group size="sm" class="mb-2" label="Search">
						<b-input-group-prepend is-text>
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>
						<b-form-input
							id="filter-input"
							v-model="filter"
							type="search"
							placeholder="Search"
						></b-form-input>
					</b-input-group>
				</b-col>
			</b-row>
		</b-card>
		<b-overlay :show="items == null" rounded="sm" class="overlay-placeholder">
			<b-card>
				<b-table
					hover
					:items="list"
					:fields="fields"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
				>
					<template #cell(fileName)="row">
						{{ row.value.substring(37) }}
					</template>
					<template #cell(UserString)="row">
						<span v-if="row.value">{{ row.value }}</span>
						<span class="text-muted" v-else>{{ row.item.NomeDaFile }}</span>
					</template>
					<template #cell(AziendaString)="row">
						<span v-if="row.value">{{ row.value }}</span>
						<span class="text-muted" v-else>{{ row.item.AziendaDaFile }}</span>
					</template>
					<template #cell(StatoText)="row">
						<b-badge v-if="row.item['ID_Stato'] == 1" variant="danger">{{
							row.value
						}}</b-badge>
						<b-badge v-if="row.item['ID_Stato'] == 2" variant="info">{{
							row.value
						}}</b-badge>
						<b-badge v-if="row.item['ID_Stato'] == 3" variant="success">{{
							row.value
						}}</b-badge>
					</template>
					<template #cell(actions)="row">
						<b-button
							v-if="row.item['ID_Stato'] == 1"
							variant="outline-primary"
							size="sm"
							class="talent-list-button"
							@click="currentItem = row.item"
							v-b-toggle.sidebar-talentAssociation
							>Associa</b-button
						>
						<b-button
							v-if="row.item['ID_Stato'] == 2"
							variant="outline-primary"
							size="sm"
							class="talent-list-button"
							@click="goToReport(row.item.ID)"
							>Analizza</b-button
						>
						<b-button
							v-if="row.item['ID_Stato'] == 3"
							variant="outline-primary"
							size="sm"
							class="talent-list-button"
							@click="goToReport(row.item.ID)"
							>Visualizza</b-button
						>

						<b-dropdown
							size="sm"
							variant="outline-primary"
							class="ml-2"
							toggle-class="text-decoration-none"
							no-caret
						>
							<template #button-content>
								<b-icon-gear-fill></b-icon-gear-fill>
							</template>
							<b-dropdown-item
								href="#"
								@click="dissociateItem(row.item)"
								v-if="row.item['ID_Stato'] != 1"
								>Dissocia</b-dropdown-item
							>
							<b-dropdown-item
								@click="
									currentItem = row.item;
									showDeleteModal = true;
								"
								>Elimina</b-dropdown-item
							>
						</b-dropdown>
					</template>
				</b-table>
				<b-pagination
					v-model="currentPage"
					:total-rows="totalRows"
					:per-page="perPage"
					align="fill"
					size="sm"
					class="mt-4 mb-1"
				></b-pagination>
			</b-card>
		</b-overlay>
		<b-sidebar
			class="right-sidebar"
			id="sidebar-talentAssociation"
			right
			shadow
			lazy
			@hidden="resetData"
		>
			<div class="content-wrapper my-3 mx-1">
				<h4>Connect user</h4>
				<h5 class="mt-4">Info from file</h5>
				<p>
					Name: <b>{{ currentItem.NomeDaFile }}</b>
				</p>
				<p></p>
				<p>
					Company: <b>{{ currentItem.AziendaDaFile }}</b>
				</p>
				<h5 class="mt-4">User selection</h5>
				<b-row class="mt-2">
					<b-col>
						<b-input-group class="mb-2" label="Search">
							<b-input-group-prepend is-text>
								<b-icon icon="search"></b-icon>
							</b-input-group-prepend>
							<b-form-input
								v-model="userFilter"
								type="search"
								placeholder="Search"
							></b-form-input>
						</b-input-group>
					</b-col>
					<b-col>
						<b-form-select
							v-model="companyListSelected"
							:options="filterCompanyList"
						></b-form-select>
					</b-col>
				</b-row>
				<div class="checkbox-container mt-2" style="height: 14rem">
					<b-row
						v-for="user in filteredUsers"
						:key="user.id"
						class="p-2 align-items-center"
					>
						<b-col md="1">
							<b-form-checkbox v-model="tmpUser" :value="user.id">
							</b-form-checkbox>
						</b-col>
						<b-col md="5"> {{ user.nome }} {{ user.cognome }} </b-col>
						<b-col md="5">
							{{ getCompanyName(user.id_company) }}
						</b-col>
					</b-row>
				</div>
				<div class="mt-5 mb-5">
					<b-button
						squared
						variant="primary"
						size="lg"
						class="big-button"
						@click="saveItem"
						>Confirm</b-button
					>
				</div>
			</div>
		</b-sidebar>
		<b-modal
			v-model="showDeleteModal"
			title="Attention!"
			ok-title="Confirm"
			cancel-title="Cancel"
			@hidden="resetData"
			@ok="deleteItem(currentItem.ID)"
			>Do you really want to delete the selected element?</b-modal
		>
	</div>
</template>

<script>
	import AxiosService from "../axiosServices/AxiosService";
	export default {
		components: {},
		data() {
			return {
				showDeleteModal: false,
				items: null,
				fields: [
					{ key: "ID", label: "ID" },
					{ key: "fileName", label: "Test" },
					{ key: "UserString", label: "User" },
					{ key: "AziendaString", label: "Company" },
					{ key: "StatoText", label: "Status" },
					{ key: "actions", label: "Actions" },
				],
				currentPage: 1,
				perPage: 10,
				filter: "",

				users: [],
				userFilter: "",
				companyList: [],
				companyListSelected: null,

				file: null,
				loadingFile: false,
				isLoading: false,

				currentItem: {},
				tmpUser: null,
			};
		},
		service: null,
		userService: null,
		created() {
			this.service = new AxiosService("AnalisiDelTalento");
			this.userService = new AxiosService("User");
		},
		mounted() {
			this.service.read().then((data) => {
				this.items = data;
			});
			this.service
				.getOptions("Company")
				.then((data) => (this.companyList = data));
			this.userService.read().then((data) => {
				this.users = data;
			});
		},
		methods: {
			async uploadItem() {
				let fileUrl = "";
				this.isLoading = true;
				this.loadingFile = true;
				if (this.file) {
					if (this.file.size > 3 * 1024 * 1024) {
						alert("I file non possono superare il massimo di 3MB");
						return;
					}
					const formData = new FormData();
					formData.append("file", this.file);
					let fileName = await this.service
						.uploadFile(formData)
						.then((res) => res.data)
						.finally(() => {
							this.loadingFile = false;
						});
					if (fileName) {
						fileUrl = "/Drive/" + fileName;
					}
				}

				if (fileUrl) {
					const fileUrlObject = { filePath: fileUrl };
					this.service
						.create(fileUrlObject)
						.then((res) => {
							if (res) {
								this.items = [...res, ...this.items];
								this.file = null;
								this.$successToast();
							} else {
								this.$errorToast();
								return false;
							}
						})
						.catch(() => {
							this.$errorToast();
							return false;
						});
				} else {
					this.$errorToast();
					return false;
				}
				this.isLoading = false;
			},
			getCompanyName(id) {
				const item = this.companyList.find((x) => x.value === id);
				return item ? item.text : "";
			},
			resetData() {
				this.currentItem = {};
				this.tmpUser = null;
				this.companyListSelected = null;
				this.userFilter = "";
			},
			saveItem() {
				let item = JSON.parse(JSON.stringify(this.currentItem));
				item.ID_User = this.tmpUser;
				item.ID_Stato = 2;

				if (item.ID && this.tmpUser) {
					this.service
						.putCustomEndpoint("AnalisiDelTalentoPut", item.ID, item)
						.then((response) => {
							if (response.ID == item.ID) {
								this.currentItem.ID_User = response.ID_User;
								this.currentItem.AziendaString = response.AziendaString;
								this.currentItem.UserString = response.UserString;
								this.currentItem.ID_Stato = response.ID_Stato;
								this.currentItem.StatoText = response.StatoText;
								this.$root.$emit(
									"bv::toggle::collapse",
									"sidebar-talentAssociation"
								);
								this.$successToast();
							} else {
								this.$errorToast();
								return false;
							}
						})
						.catch(() => {
							this.$errorToast();
							return false;
						});
				} else {
					this.$errorToast();
					return false;
				}
			},
			dissociateItem(paramItem) {
				let item = JSON.parse(JSON.stringify(paramItem));
				item.ID_User = null;
				item.ID_Stato = 1;

				if (item.ID) {
					this.service
						.putCustomEndpoint("AnalisiDelTalentoPut", item.ID, item)
						.then((response) => {
							if (response.ID == item.ID) {
								paramItem.ID_User = response.ID_User;
								paramItem.AziendaString = response.AziendaString;
								paramItem.UserString = response.UserString;
								paramItem.ID_Stato = response.ID_Stato;
								paramItem.StatoText = response.StatoText;
								this.$successToast();
							} else {
								this.$errorToast();
								return false;
							}
						})
						.catch(() => {
							this.$errorToast();
							return false;
						});
				} else {
					this.$errorToast();
					return false;
				}
			},
			deleteItem(id) {
				this.service
					.deleteCustomEndpoint("AnalisiDelTalentoDelete", id)
					.then((res) => {
						if (res) {
							this.$successToast();
							this.items.splice(0);
							this.service.read().then((data) => {
								this.items = data;
							});
						} else {
							this.$errorToast();
						}
					});
			},
			goToReport(idReport) {
				this.$router.push({
					name: "Talent Analysis Report",
					params: { reportId: idReport },
				});
			},
		},
		computed: {
			totalRows() {
				return this.list ? this.list.length : 0;
			},
			list() {
				if (this.items == null) return [];
				return this.items;
			},
			filterCompanyList() {
				return [{ value: null, text: "All companies" }, ...this.companyList];
			},
			filteredUsers() {
				if (this.userFilter || this.companyListSelected) {
					return this.users.filter((item) => {
						const concatString = item.nome + " " + item.cognome;
						if (
							!concatString
								.toLowerCase()
								.includes(this.userFilter.toLowerCase())
						) {
							return false;
						}
						if (
							this.companyListSelected &&
							item.id_company != this.companyListSelected
						) {
							return false;
						}
						return true;
					});
				} else {
					return this.users;
				}
			},
		},
		watch: {
			filter: {
				handler() {
					this.currentPage = 1;
				},
			},
		},
	};
</script>
